import React, { useState, useEffect, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import titleLogoDefault from "../../assets/images/svg/header-corp-white.svg";
import expressCoachIcon from "../../assets/images/ExpressCoachIcon.png";
import { ThemeContext } from "../../themes/themeContext";

//import { ThemeContext } from "../themes/themeContext";
import { Link, useNavigate } from "react-router-dom";
import Translate, { reactTranslateChangeLanguage } from "translate-components";

import PasswordUpdate from "../login-components/PasswordChange";
import * as applicationActions from "../../data/Application/applicationActions";
import * as landingActions from "../../data/Landing/landingActions";
import * as roleActions from "../../data/InstituteRole/instituteRoleActions";

//---------------------Translation
const Header = ({ userSession, logout, redirectUrl, showLogin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  /* eslint-disable no-unused-vars */
  const [menuMyCourses, setMenuMyCourses] = useState(false);
  const [menuAllCourses, setMenuAllCourses] = useState(false);
  const [menuAllCategories, setMenuAllCategories] = useState(false);
  const [menuAllLanguages, setMenuAllLanguages] = useState(false);
  const [menuAllUserSettings, setMenuAllUserSettings] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(null);

  const lang = useSelector((state) =>
    state.application != null ? state.application.selectedLanguageCode : "en"
  );
  const instituteRoles = useSelector((state) =>state.instituteRole.instituteRoles);
   
  const casRefreshToken = useSelector((state) =>
    state.userSession != null ? state.userSession.casRefreshToken : null
  );

  const [hasAdminRole, setHasAdminRole] = useState(false);

  /* eslint-enable no-unused-vars */
  const [navBar, setNavBar] = useState(false);

  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleSwitchLanguage = (e) => {
    dispatch(applicationActions.switchLanguage(e));
    reactTranslateChangeLanguage(e);
  };

  const activeMenu = (item) => {
    clearMenu();
    switch (item) {
      case 1:
        setMenuMyCourses(!menuMyCourses);
        break;
      case 2:
        setMenuAllCategories(!menuAllCategories);
        break;
      case 3:
        setMenuAllLanguages(!menuAllLanguages);
        break;
      case 4:
        setMenuAllUserSettings(!menuAllUserSettings);
    }
    setCurrentMenu(item);
  };

  const clearMenu = () => {
    setCurrentMenu(null);
    setMenuAllCategories(false);
    setMenuAllLanguages(false);
    setMenuAllCourses(false);
    setMenuMyCourses(false);
    setMenuAllUserSettings(false);
  };

  const fetchData = useCallback(() => {
    //Remove of Modules and Products User List
    //dispatch(landingActions.getAllModules(userId, lang));
    //dispatch(landingActions.getAllProducts(lang));
    if(userSession && userSession.isLoggedIn())
      dispatch(roleActions.getInstuteRoleActionsByEmployee(userSession.userId));
  } );

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGE_TITTLE;
  }, []);

  useEffect(() => {
    fetchData();
  }, userSession);

  const changeHeader = () => {
    if (window.scrollY >= 80) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  };

  window.addEventListener("scroll", changeHeader);

  const isAdmin = () => {
    if (instituteRoles != null) {
      let role = instituteRoles.find((role) => role.name === "admin" || role.name === "leader");
      if (role != null) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    setHasAdminRole(isAdmin());
  },[instituteRoles, userSession]);

    
  return (
    <>
      <div
        className={navBar ? "header active" : "header"}
        style={{ background: theme.header.background }}
      >
        <div className="header-top-container">
          <div
            className="header-top-ecoach"
            style={{
              background: theme.header.background,
              color: theme.header.color,
            }}
          >
            {userSession &&
              userSession.isLoggedIn() &&
              casRefreshToken &&
              process.env.REACT_APP_CONNECT_WITH_EXPRESS_COACH === "1" && (
                <>
                  <img src={expressCoachIcon} style={{ height: "25px" }} />
                  &nbsp;&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_EXPRESS_COACH_LOGIN_URL}${casRefreshToken}&cid=${process.env.REACT_APP_EXPRESS_COACH_CLIENT_ID}`}
                    style={{
                      color: theme.header.color,
                      textDecoration: "none",
                    }}
                  >
                    <Translate>main.header.expresscoach</Translate>
                  </a>
                </>
              )}
          </div>

          <div
            className="header-top-band"
            style={{
              background: theme.header.background,
              color: theme.header.color,
            }}
          >
            <span className="plus material-icons">language</span>
            <a
              onClick={() => handleSwitchLanguage("en")}
              style={{ color: theme.header.color }}
            >
              English
            </a>
            <div>|</div>
            <a
              onClick={() => handleSwitchLanguage("es")}
              style={{
                color: theme.header.color,
              }}
            >
              Español
            </a>
          </div>
        </div>

        <div
          className="header-container "
          style={{ color: theme.header.color }}
        >
          <div className="d-flex flex-column">
            <Link
              className="header-logo"
              to={userSession && userSession.isLoggedIn() ? "/dashboard" : "/"}
            >
              <img src={titleLogoDefault} alt="" className="logo-large" />
              <img src={titleLogoDefault} alt="" className="logo-small" />
            </Link>
            <a
              href="https://www.eleadertech.com"
              target="_blank"
              rel="noopener noreferrer"
              className="headerLegend"
              style={{ color: theme.header.color }}
            >
              Powered by eLeaderTech
            </a>
          </div>

          <nav>
            <input id="menu-toggle" type="checkbox" />
            <label className="menu-button-container" htmlFor="menu-toggle">
              <div className="menu-button"></div>
            </label>
            <ul className="menu">
            {hasAdminRole && <li
                className="menu-item"
                style={{
                  backgroundColor: theme.header.menu_item.background,
                }}
                onMouseOut={() => clearMenu()}
              >
                <Link to={"/admin"}>
                  <span className="item-outer">
                    <span className="item-inner">
                      <span
                        className="item-text"
                        style={{ color: theme.header.menu_item.color }}
                      >
                        <Translate>Administration</Translate>
                      </span>
                    </span>
                  </span>
                </Link>
              </li>}
              <li
                className="menu-item"
                style={{
                  backgroundColor: theme.header.menu_item.background,
                }}
                onMouseOut={() => clearMenu()}
              >
                <Link to={"/"}>
                  <span className="item-outer">
                    <span className="item-inner">
                      <span
                        className="item-text"
                        style={{ color: theme.header.menu_item.color }}
                      >
                        <Translate>Reset_Token_Home</Translate>
                      </span>
                    </span>
                  </span>
                </Link>
              </li>
              <li
                className="menu-item"
                style={{
                  backgroundColor: theme.header.menu_item.background,
                }}
                onMouseOut={() => clearMenu()}
              >
                <Link
                  to={
                    userSession && userSession.isLoggedIn() ? "/dashboard" : "/"
                  }
                >
                  <span className="item-outer">
                    <span className="item-inner">
                      <span
                        className="item-text"
                        style={{ color: theme.header.menu_item.color }}
                      >
                        <Translate>mycourses</Translate>
                      </span>
                    </span>
                    <span className="plus material-icons hidden">
                      keyboard_arrow_down
                    </span>
                  </span>
                </Link>
              </li>

              {userSession && userSession.isLoggedIn() ? (
                <>
                  <li
                    className="menu-item"
                    style={{
                      backgroundColor: theme.header.menu_item.background,
                    }}
                    onClick={() => activeMenu(4)}
                  >
                    <a>
                      <span className="item-outer">
                        <span className="item-inner">
                          <span
                            className="item-text"
                            style={{ color: theme.header.menu_item.color }}
                          >
                            <span>
                              {userSession.firstName +
                                " " +
                                userSession.lastName +
                                " "}
                            </span>
                          </span>
                        </span>
                        <span className="plus material-icons">
                          keyboard_arrow_down
                        </span>
                      </span>
                      <div
                        className={
                          menuAllUserSettings ? "menu-second" : "hidden"
                        }
                        style={{
                          backgroundColor: theme.header.inner_item.background,
                        }}
                      >
                        <div className="menu-inner">
                          <ul>
                            {userSession.hasLicenses && (
                              <li className="menu-item">
                                <a href="/UserAdmin">
                                  <span className="edgtf-item-outer">
                                    <span className="edgtf-item-inner">
                                      <span
                                        className="edgtf-item-text"
                                        style={{
                                          color: theme.header.inner_item.color,
                                        }}
                                      >
                                        &nbsp;
                                        <span className="plus material-icons">
                                          settings
                                        </span>
                                        <Translate>Admin</Translate>
                                      </span>
                                    </span>
                                    <span className="plus"></span>
                                  </span>
                                </a>
                              </li>
                            )}
                            <li className="menu-item">
                              <a onClick={() => setShowChangePassword(true)}>
                                <span className="edgtf-item-outer">
                                  <span className="edgtf-item-inner">
                                    <span
                                      className="edgtf-item-text"
                                      style={{
                                        color: theme.header.inner_item.color,
                                      }}
                                    >
                                      &nbsp;
                                      <span className="plus material-icons">
                                        lock
                                      </span>
                                      <Translate>
                                        Update.password.title
                                      </Translate>
                                    </span>
                                  </span>
                                  <span className="plus"></span>
                                </span>
                              </a>
                            </li>
                            <li className="menu-item">
                              <a onClick={() => logout()}>
                                <span className="edgtf-item-outer">
                                  <span className="edgtf-item-inner">
                                    <span
                                      className="edgtf-item-text"
                                      style={{
                                        color: theme.header.inner_item.color,
                                      }}
                                    >
                                      &nbsp;
                                      <span className="plus material-icons">
                                        logout
                                      </span>
                                      <Translate>Close_Token_Login</Translate>
                                    </span>
                                  </span>
                                  <span className="plus"></span>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </a>
                  </li>
                </>
              ) : (
                showLogin == true && (
                  <>
                    <li className="menu-item" onMouseOver={() => clearMenu()}>
                      <a href={"" + redirectUrl}>
                        <span className="item-outer">
                          <span className="item-inner">
                            <span
                              className="item-text"
                              style={{ color: theme.header.menu_item.color }}
                            >
                              <Translate>Reset_Token_Login</Translate>
                            </span>
                          </span>
                          <span className="plus material-icons hidden">
                            keyboard_arrow_down
                          </span>
                        </span>
                      </a>
                    </li>
                  </>
                )
              )}
            </ul>
          </nav>
        </div>
      </div>

      <PasswordUpdate
        show={showChangePassword}
        onClose={() => setShowChangePassword(false)}
      ></PasswordUpdate>
    </>
  );
};

Header.propTypes = {
  redirectUrl: PropTypes.string,
  token: PropTypes.string,
  userSession: PropTypes.object,
  logout: PropTypes.func,
  hideUserName: PropTypes.bool,
  showLogin: PropTypes.bool,
};

export default Header;
